import { ReactNode, useContext } from 'react'
import { Form, Formik, FormikValues } from 'formik'
import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import Yup from '../../../utils/lang/ValidationTranslation'
import {
  DefaultApi,
  Site,
  SiteChargingLocationsEnum,
  SiteExistingVehiclesEnum,
  SiteRegisteredAtNetOperatorEnum,
  SiteSupplyCostsStateEnum,
  SiteVehicleTypeEnum,
} from '../../../services'
import { SnackbarContext } from '../../../context/Snackbar'
import CustomTranslations from '../../../utils/lang/CustomTranslations'
import BackButtonStepper from '../../../components/Button/BackButtonStepper'
import NextButtonStepper from '../../../components/Button/NextButtonStepper'
import StepperContext from '../../../context/Stepper'
import CustomAccordion, {
  AccordionDetailsTitle,
} from '../../../components/Accordion/CustomAccordion'
import FormikRadioGroup, {
  booleanRadioType,
} from '../../../components/Input/FormikRadioGroup'
import {
  chargingLocationsLabel,
  existingVehiclesLabel,
  networkOperatorRegisteredLabel,
  supplyCostsStateLabel,
  vehicleTypeLabel,
} from '../../../utils/labelHelper'
import CustomTexts from '../../../utils/lang/CustomTexts'
import FormikMultiSelect from '../../../components/Input/FormikMultiSelect'
import FormikCheckbox from '../../../components/Input/FormikCheckbox'
import FormikNumberField from '../../../components/Input/FormikNumberField'

interface PtjSiteProps {
  site: Site
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
  })
)

const {
  REQUIRED_MULTISELECT,
  AMOUNT_LABEL,
  COSTS_LABEL,
  SAVE_SUCCESS,
  LOCATIONS_LABEL,
} = CustomTranslations

const PtjSiteForm = (props: PtjSiteProps): JSX.Element => {
  const { site } = props
  const { reportId, locationId } = useParams<{
    reportId: string
    locationId: string
  }>()
  const defaultApi = new DefaultApi()
  const { openSnackbar, openSnackbarError } = useContext(SnackbarContext)
  const { setActiveStep } = useContext(StepperContext)
  const {
    existingVehicles: existingVehiclesText,
    electricVehiclesTitle,
    employeeVehicles: employeeVehiclesText,
    locationRelatedVehicles,
    vehicleType,
    totalCompanyVehicles,
    totalCompanyVehiclesTitle,
    chargingLocations,
    networkOperatorTitle,
    registeredAtNetOperator,
    suppliesPerChargingStation,
    supplyCostsPerLocation,
    thereOfPkw,
    vehiclesN1,
    vehiclesN2,
    vehiclesN3,
    vehiclesNotKnown,
  } = CustomTexts.form.site

  const ptjEmployeeVehiclesNotKnownValid = Yup.number()
    .when(['employeeVehiclesNotKnown'], {
      is: (employeeVehiclesNotKnown: boolean) => !employeeVehiclesNotKnown,
      then: Yup.number().min(0).required(),
      otherwise: Yup.number().notRequired().nullable(),
    })
    .test(
      'totalEmployeeNotGreatherThenAllCompalyVehiclesClass',
      CustomTexts.form.maxtotalCompanyVehicles,
      (val, context) => {
        return (
          (context.parent.employeeVehiclesPkw ?? 0) +
            (context.parent.employeeVehiclesN1 ?? 0) +
            (context.parent.employeeVehiclesN2 ?? 0) +
            (context.parent.employeeVehiclesN3 ?? 0) <=
          (context.parent.employeeVehicles ?? 0)
        )
      }
    )

  const ptjFullElectricCompanyNotKnownValid = Yup.number()
    .when(['existingVehicles', 'fullElectricCompanyNotKnown'], {
      is: (
        existingVehicles: SiteExistingVehiclesEnum,
        fullElectricCompanyNotKnown: boolean
      ) =>
        existingVehicles !== SiteExistingVehiclesEnum.No &&
        !fullElectricCompanyNotKnown,
      then: Yup.number().min(0).required(),
      otherwise: Yup.number().notRequired().nullable(),
    })
    .test(
      'totalCompanyNotGreatherThenAllCompalyVehiclesClass',
      CustomTexts.form.maxtotalCompanyVehicles,
      (val, context) => {
        if (context.parent.existingVehicles !== SiteExistingVehiclesEnum.No) {
          return (
            (context.parent.fullElectricCompanyPkw ?? 0) +
              (context.parent.fullElectricClassCompanyN1 ?? 0) +
              (context.parent.fullElectricClassCompanyN2 ?? 0) +
              (context.parent.fullElectricClassCompanyN3 ?? 0) <=
            (context.parent.totalCompanyVehicles ?? 0)
          )
        }
        return true
      }
    )

  const existingVehiclesBoolValid = Yup.boolean().when('existingVehicles', {
    is: (existingVehicles: SiteExistingVehiclesEnum) =>
      existingVehicles !== SiteExistingVehiclesEnum.No,
    then: Yup.boolean().required(),
    otherwise: Yup.boolean().notRequired().nullable(),
  })

  const validationSchema = Yup.object().shape({
    existingVehicles: Yup.string()
      .oneOf(Object.values(SiteExistingVehiclesEnum))
      .required(),
    vehicleType: Yup.string()
      .oneOf(Object.values(SiteVehicleTypeEnum))
      .required(),
    locationRelatedVehicles: Yup.string()
      .oneOf(['true', 'false'])
      .when('existingVehicles', {
        is: (existingVehicles: SiteExistingVehiclesEnum) =>
          existingVehicles !== SiteExistingVehiclesEnum.No,
        then: Yup.string().oneOf(['true', 'false']).required(),
        otherwise: Yup.string().notRequired().nullable(),
      }),
    chargingLocations: Yup.array().when('existingVehicles', {
      is: (existingVehicles: SiteExistingVehiclesEnum) =>
        existingVehicles !== SiteExistingVehiclesEnum.No,
      then: Yup.array().min(1, REQUIRED_MULTISELECT).required(),
      otherwise: Yup.array().notRequired().nullable(),
    }),
    totalCompanyVehicles: Yup.number().when('existingVehicles', {
      is: (existingVehicles: SiteExistingVehiclesEnum) =>
        existingVehicles !== SiteExistingVehiclesEnum.No,
      then: Yup.number().min(0).required(),
      otherwise: Yup.number().notRequired().nullable(),
    }),
    employeeVehicles: Yup.number().min(0).required(),
    registeredAtNetOperator: Yup.string()
      .oneOf(Object.values(SiteRegisteredAtNetOperatorEnum))
      .required(),
    suppliesPerChargingStation: Yup.number().min(0).required(),
    supplyCostsPerLocation: Yup.number().min(0).required(),
    supplyCostsState: Yup.string()
      .oneOf(Object.values(SiteSupplyCostsStateEnum))
      .required(),
    fullElectricCompanyPkw: ptjFullElectricCompanyNotKnownValid,
    fullElectricClassCompanyN1: ptjFullElectricCompanyNotKnownValid,
    fullElectricClassCompanyN2: ptjFullElectricCompanyNotKnownValid,
    fullElectricClassCompanyN3: ptjFullElectricCompanyNotKnownValid,
    fullElectricCompanyNotKnown: existingVehiclesBoolValid,
    employeeVehiclesPkw: ptjEmployeeVehiclesNotKnownValid,
    employeeVehiclesN1: ptjEmployeeVehiclesNotKnownValid,
    employeeVehiclesN2: ptjEmployeeVehiclesNotKnownValid,
    employeeVehiclesN3: ptjEmployeeVehiclesNotKnownValid,
    employeeVehiclesNotKnown: existingVehiclesBoolValid,
  })

  const handleSubmit = (values: FormikValues): void => {
    const isSiteExistingVehicle =
      values.existingVehicles !== SiteExistingVehiclesEnum.No

    if (locationId && reportId)
      defaultApi
        .reportReportIdLocationLocationIdSitePut(locationId, reportId, {
          ...values,
          ...(values.employeeVehiclesNotKnown && {
            employeeVehiclesPkw: undefined,
            employeeVehiclesN1: undefined,
            employeeVehiclesN2: undefined,
            employeeVehiclesN3: undefined,
          }),
          ...(isSiteExistingVehicle
            ? {
                locationRelatedVehicles:
                  values.locationRelatedVehicles === 'true',
                chargingLocations: values.chargingLocations ?? [],
                totalCompanyVehicles: values.totalCompanyVehicles,
                fullElectricCompanyNotKnown:
                  values.fullElectricCompanyNotKnown ?? undefined,
                ...(values.fullElectricCompanyNotKnown && {
                  fullElectricClassCompanyN1: undefined,
                  fullElectricClassCompanyN2: undefined,
                  fullElectricClassCompanyN3: undefined,
                  fullElectricCompanyPkw: undefined,
                }),
              }
            : {
                totalCompanyVehicles: undefined,
                locationRelatedVehicles: undefined,
                chargingLocations: undefined,
                fullElectricClassCompanyN1: undefined,
                fullElectricClassCompanyN2: undefined,
                fullElectricClassCompanyN3: undefined,
                fullElectricCompanyPkw: undefined,
                fullElectricCompanyNotKnown: undefined,
              }),
        })
        .then(() => {
          openSnackbar(SAVE_SUCCESS, 'success')
          setActiveStep((prevActiveStep) => prevActiveStep + 1)
        })
        .catch((e: AxiosError) => openSnackbarError(e))
  }
  const classes = useStyles()

  return (
    <Formik
      enableReinitialize
      initialValues={{
        existingVehicles: site.existingVehicles ?? '',
        vehicleType: site.vehicleType ?? '',
        locationRelatedVehicles: site.locationRelatedVehicles?.toString(),
        chargingLocations: site.chargingLocations ?? [],
        totalCompanyVehicles: site.totalCompanyVehicles ?? '',
        fullElectricCompanyPkw: site.fullElectricCompanyPkw ?? '',
        fullElectricClassCompanyN1: site.fullElectricClassCompanyN1 ?? '',
        fullElectricClassCompanyN2: site.fullElectricClassCompanyN2 ?? '',
        fullElectricClassCompanyN3: site.fullElectricClassCompanyN3 ?? '',
        fullElectricCompanyNotKnown: site.fullElectricCompanyNotKnown ?? false,
        employeeVehicles: site.employeeVehicles ?? '',
        employeeVehiclesPkw: site.employeeVehiclesPkw ?? '',
        employeeVehiclesN1: site.employeeVehiclesN1 ?? '',
        employeeVehiclesN2: site.employeeVehiclesN2 ?? '',
        employeeVehiclesN3: site.employeeVehiclesN3 ?? '',
        employeeVehiclesNotKnown: site.employeeVehiclesNotKnown ?? false,
        suppliesPerChargingStation: site.suppliesPerChargingStation ?? '',
        supplyCostsPerLocation: site.supplyCostsPerLocation ?? '',
        supplyCostsState: site.supplyCostsState ?? '',
        registeredAtNetOperator: site.registeredAtNetOperator ?? '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values): void => handleSubmit(values)}
    >
      {({
        submitForm,
        dirty,
        setFieldValue,
        errors,
        touched,
        validateForm,
        isSubmitting,
        values,
      }): JSX.Element => (
        <Form autoComplete="off">
          <Box mt={2} mb={4}>
            <CustomAccordion
              id="electricVehicles"
              title={electricVehiclesTitle}
              defaultExpanded
            >
              <FormikRadioGroup
                name="existingVehicles"
                error={Boolean(
                  touched.existingVehicles && errors.existingVehicles
                )}
                formLabel={existingVehiclesText}
                options={Object.values(SiteExistingVehiclesEnum).map((type) => {
                  return {
                    title: existingVehiclesLabel(type),
                    value: type,
                  }
                })}
              />
              <Divider />
              <FormikRadioGroup
                name="vehicleType"
                error={Boolean(touched.vehicleType && errors.vehicleType)}
                formLabel={vehicleType}
                options={Object.values(SiteVehicleTypeEnum).map((type) => {
                  return {
                    title: vehicleTypeLabel(type),
                    value: type,
                  }
                })}
              />
              <Divider style={{ marginBottom: '1rem' }} />
              {values.existingVehicles !== SiteExistingVehiclesEnum.No ? (
                <>
                  <FormikRadioGroup
                    name="locationRelatedVehicles"
                    formLabel={locationRelatedVehicles}
                    tooltip={CustomTexts.tooltips.locationRelatedVehicles}
                    error={Boolean(
                      touched.locationRelatedVehicles &&
                        errors.locationRelatedVehicles
                    )}
                    options={booleanRadioType}
                  />
                  <Divider />
                  <AccordionDetailsTitle title={chargingLocations} />
                  <FormikMultiSelect
                    name="chargingLocations"
                    label={LOCATIONS_LABEL}
                    error={Boolean(
                      touched.chargingLocations && errors.chargingLocations
                    )}
                    errorMsg={errors.chargingLocations as string | undefined}
                    defaultValue={site.chargingLocations || []}
                    renderValue={(selected: unknown): ReactNode => {
                      const cast = selected as string[]
                      return cast
                        .map((item: string) =>
                          chargingLocationsLabel(
                            item as SiteChargingLocationsEnum
                          )
                        )
                        .join(', ')
                    }}
                    setFieldValue={setFieldValue}
                    options={Object.values(SiteChargingLocationsEnum).map(
                      (type) => {
                        return {
                          title: chargingLocationsLabel(type),
                          value: type,
                        }
                      }
                    )}
                  />
                  <Divider style={{ marginBottom: '1rem' }} />
                  <AccordionDetailsTitle
                    title={totalCompanyVehiclesTitle}
                    tooltip={CustomTexts.tooltips.totalCompanyVehicles}
                  />
                  <Box display="flex" mt={4} className={classes.wrapper}>
                    <Box mr={14}>
                      <FormikNumberField
                        name="totalCompanyVehicles"
                        label={AMOUNT_LABEL}
                        placeholder={AMOUNT_LABEL}
                        title={totalCompanyVehicles}
                        error={Boolean(
                          touched.totalCompanyVehicles &&
                            errors.totalCompanyVehicles
                        )}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Box display="flex" mt={4} className={classes.wrapper}>
                      <Box mr={14}>
                        <FormikNumberField
                          name="fullElectricCompanyPkw"
                          label={AMOUNT_LABEL}
                          placeholder={AMOUNT_LABEL}
                          title={thereOfPkw}
                          error={Boolean(
                            touched.fullElectricCompanyPkw &&
                              touched.fullElectricCompanyNotKnown &&
                              errors.fullElectricCompanyPkw
                          )}
                          style={{ display: 'block' }}
                          disabled={Boolean(values.fullElectricCompanyNotKnown)}
                        />
                      </Box>
                      <Box>
                        <FormikNumberField
                          name="fullElectricClassCompanyN1"
                          label={AMOUNT_LABEL}
                          placeholder={AMOUNT_LABEL}
                          title={vehiclesN1}
                          error={Boolean(
                            touched.fullElectricClassCompanyN1 &&
                              touched.fullElectricCompanyNotKnown &&
                              errors.fullElectricClassCompanyN1
                          )}
                          style={{ display: 'block' }}
                          disabled={Boolean(values.fullElectricCompanyNotKnown)}
                        />
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      mt={4}
                      className={classes.wrapper}
                      mb={4}
                    >
                      <Box mr={10}>
                        <FormikNumberField
                          name="fullElectricClassCompanyN2"
                          label={AMOUNT_LABEL}
                          placeholder={AMOUNT_LABEL}
                          title={vehiclesN2}
                          error={Boolean(
                            touched.fullElectricClassCompanyN2 &&
                              touched.fullElectricCompanyNotKnown &&
                              errors.fullElectricClassCompanyN2
                          )}
                          style={{ display: 'block' }}
                          disabled={Boolean(values.fullElectricCompanyNotKnown)}
                        />
                      </Box>
                      <Box>
                        <FormikNumberField
                          name="fullElectricClassCompanyN3"
                          label={AMOUNT_LABEL}
                          placeholder={AMOUNT_LABEL}
                          title={vehiclesN3}
                          error={Boolean(
                            touched.fullElectricClassCompanyN3 &&
                              touched.fullElectricCompanyNotKnown &&
                              errors.fullElectricClassCompanyN3
                          )}
                          style={{ display: 'block' }}
                          disabled={Boolean(values.fullElectricCompanyNotKnown)}
                        />
                      </Box>
                    </Box>
                    <FormikCheckbox
                      name="fullElectricCompanyNotKnown"
                      label={vehiclesNotKnown}
                      disabled={Boolean(
                        values.fullElectricCompanyPkw ||
                          values.fullElectricClassCompanyN1 ||
                          values.fullElectricClassCompanyN2 ||
                          values.fullElectricClassCompanyN3
                      )}
                    />
                  </Box>

                  <Divider style={{ marginBottom: '1rem' }} />
                </>
              ) : (
                <></>
              )}
              <FormikNumberField
                name="employeeVehicles"
                label={AMOUNT_LABEL}
                placeholder={AMOUNT_LABEL}
                title={employeeVehiclesText}
                error={Boolean(
                  touched.employeeVehicles && errors.employeeVehicles
                )}
                tooltip={CustomTexts.tooltips.employeeVehicles}
              />
              <>
                <Box display="flex" mt={4} className={classes.wrapper}>
                  <Box mr={14}>
                    <FormikNumberField
                      name="employeeVehiclesPkw"
                      label={AMOUNT_LABEL}
                      placeholder={AMOUNT_LABEL}
                      title={thereOfPkw}
                      error={Boolean(
                        touched.employeeVehiclesPkw &&
                          touched.employeeVehiclesNotKnown &&
                          errors.employeeVehiclesPkw
                      )}
                      style={{ display: 'block' }}
                      disabled={Boolean(values.employeeVehiclesNotKnown)}
                    />
                  </Box>
                  <Box>
                    <FormikNumberField
                      name="employeeVehiclesN1"
                      label={AMOUNT_LABEL}
                      placeholder={AMOUNT_LABEL}
                      title={vehiclesN1}
                      error={Boolean(
                        touched.employeeVehiclesN1 &&
                          touched.employeeVehiclesNotKnown &&
                          errors.employeeVehiclesN1
                      )}
                      style={{ display: 'block' }}
                      disabled={Boolean(values.employeeVehiclesNotKnown)}
                    />
                  </Box>
                </Box>
                <Box display="flex" mt={4} className={classes.wrapper} mb={4}>
                  <Box mr={10}>
                    <FormikNumberField
                      name="employeeVehiclesN2"
                      label={AMOUNT_LABEL}
                      placeholder={AMOUNT_LABEL}
                      title={vehiclesN2}
                      error={Boolean(
                        touched.employeeVehiclesN2 &&
                          touched.employeeVehiclesNotKnown &&
                          errors.employeeVehiclesN2
                      )}
                      style={{ display: 'block' }}
                      disabled={Boolean(values.employeeVehiclesNotKnown)}
                    />
                  </Box>
                  <Box>
                    <FormikNumberField
                      name="employeeVehiclesN3"
                      label={AMOUNT_LABEL}
                      placeholder={AMOUNT_LABEL}
                      title={vehiclesN3}
                      error={Boolean(
                        touched.employeeVehiclesN3 &&
                          touched.employeeVehiclesNotKnown &&
                          errors.employeeVehiclesN3
                      )}
                      style={{ display: 'block' }}
                      disabled={Boolean(values.employeeVehiclesNotKnown)}
                    />
                  </Box>
                </Box>
                <FormikCheckbox
                  name="employeeVehiclesNotKnown"
                  label={vehiclesNotKnown}
                  disabled={
                    Boolean(values.employeeVehiclesPkw) ||
                    Boolean(values.employeeVehiclesN1) ||
                    Boolean(values.employeeVehiclesN2) ||
                    Boolean(values.employeeVehiclesN3)
                  }
                />
              </>
            </CustomAccordion>
            <CustomAccordion id="networkOperator" title={networkOperatorTitle}>
              <FormikRadioGroup
                name="registeredAtNetOperator"
                error={Boolean(
                  touched.registeredAtNetOperator &&
                    errors.registeredAtNetOperator
                )}
                formLabel={registeredAtNetOperator}
                options={Object.values(SiteRegisteredAtNetOperatorEnum).map(
                  (type) => {
                    return {
                      title: networkOperatorRegisteredLabel(type),
                      value: type,
                    }
                  }
                )}
              />
              <Divider />
              <FormikNumberField
                name="suppliesPerChargingStation"
                label={AMOUNT_LABEL}
                placeholder={AMOUNT_LABEL}
                title={suppliesPerChargingStation}
                error={Boolean(
                  touched.suppliesPerChargingStation &&
                    errors.suppliesPerChargingStation
                )}
              />
              <Divider style={{ marginBottom: '1rem' }} />
              <FormikNumberField
                name="supplyCostsPerLocation"
                label={COSTS_LABEL}
                placeholder="1000"
                title={supplyCostsPerLocation}
                error={Boolean(
                  touched.supplyCostsPerLocation &&
                    errors.supplyCostsPerLocation
                )}
                tooltip={CustomTexts.tooltips.supplyCostsPerLocation}
              />
              <br />
              <FormikRadioGroup
                name="supplyCostsState"
                error={Boolean(
                  touched.supplyCostsState && errors.supplyCostsState
                )}
                formLabel=""
                options={Object.values(SiteSupplyCostsStateEnum).map((type) => {
                  return {
                    title: supplyCostsStateLabel(type),
                    value: type,
                  }
                })}
              />
            </CustomAccordion>
          </Box>
          <BackButtonStepper />
          <NextButtonStepper
            disabled={isSubmitting}
            onClick={(): void => {
              validateForm().then((res) => {
                if (Object.keys(res).length > 0) {
                  openSnackbar(CustomTranslations.INVALID_FIELDS, 'error')
                }
                if (Object.keys(res).length === 0 && !dirty)
                  setActiveStep((prevActiveStep) => prevActiveStep + 1)
                else submitForm()
              })
            }}
          />
        </Form>
      )}
    </Formik>
  )
}

export default PtjSiteForm
