import React, { ReactNode, useContext } from 'react'
import { Form, Formik, FormikValues } from 'formik'
import { Box, Divider } from '@material-ui/core'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import Yup from '../../../utils/lang/ValidationTranslation'
import {
  DefaultApi,
  LocationUsage,
  LocationUsageCommercialUseEnum,
  LocationUsageMotivationTypeEnum,
  LocationUsageReportTypeEnum,
} from '../../../services'
import { SnackbarContext } from '../../../context/Snackbar'
import CustomTranslations from '../../../utils/lang/CustomTranslations'
import NextButtonStepper from '../../../components/Button/NextButtonStepper'
import StepperContext from '../../../context/Stepper'
import CustomAccordion, {
  AccordionDetailsTitle,
} from '../../../components/Accordion/CustomAccordion'
import FormikRadioGroup, {
  booleanRadioType,
} from '../../../components/Input/FormikRadioGroup'
import CustomTexts from '../../../utils/lang/CustomTexts'
import {
  usageCommercialUseLabel,
  usageMotivationTypeLabel,
} from '../../../utils/labelHelper'
import FormikMultiSelect from '../../../components/Input/FormikMultiSelect'

interface UsageProps {
  usage: LocationUsage
}

const UsageForm = (props: UsageProps): JSX.Element => {
  const { usage } = props
  const { reportId, locationId } = useParams<{
    reportId: string
    locationId: string
  }>()
  const defaultApi = new DefaultApi()
  const { openSnackbar, openSnackbarError } = useContext(SnackbarContext)
  const { setActiveStep } = useContext(StepperContext)
  const { motivationTitle, motivationType, acquireVehicles, commercialUse } =
    CustomTexts.form.motivation
  const isCommune = usage.reportType === LocationUsageReportTypeEnum.Commune
  const { REQUIRED_MULTISELECT, SAVE_SUCCESS } = CustomTranslations

  const validationSchemaCommune = {
    motivationType: Yup.array().min(1, REQUIRED_MULTISELECT).required(),
    acquireVehicles: Yup.string().oneOf(['true', 'false']).required(),
  }

  const validationSchemaDefault = {
    commercialUse: Yup.string().required(),
    ...validationSchemaCommune,
  }

  const handleSubmit = (values: FormikValues): void => {
    if (locationId && reportId)
      defaultApi
        .reportReportIdLocationLocationIdUsagePut(locationId, reportId, {
          ...values,
          acquireVehicles: values.acquireVehicles === 'true',
          commercialUse: values.commercialUse ?? undefined,
        })
        .then(() => {
          openSnackbar(SAVE_SUCCESS, 'success')
          setActiveStep((prevActiveStep) => prevActiveStep + 1)
        })
        .catch((e: AxiosError) => openSnackbarError(e))
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...usage,
        acquireVehicles: usage.acquireVehicles?.toString(),
        motivationType: usage.motivationType ?? undefined,
      }}
      validationSchema={Yup.object().shape({
        ...(!isCommune ? validationSchemaDefault : validationSchemaCommune),
      })}
      onSubmit={(values): void => handleSubmit(values)}
    >
      {({
        submitForm,
        dirty,
        setFieldValue,
        errors,
        validateForm,
        touched,
        isSubmitting,
      }): JSX.Element => (
        <Form autoComplete="off">
          <Box mt={1} mb={4}>
            <CustomAccordion
              id="motivationType"
              title={motivationTitle}
              defaultExpanded
            >
              <AccordionDetailsTitle title={motivationType} />
              <FormikMultiSelect
                name="motivationType"
                label="Motivation"
                error={Boolean(touched.motivationType && errors.motivationType)}
                errorMsg={errors.motivationType}
                defaultValue={usage.motivationType || []}
                renderValue={(selected: unknown): ReactNode => {
                  const cast = selected as string[]
                  return cast
                    .map((item: string) =>
                      usageMotivationTypeLabel(
                        item as LocationUsageMotivationTypeEnum
                      )
                    )
                    .join(', ')
                }}
                setFieldValue={setFieldValue}
                options={Object.values(LocationUsageMotivationTypeEnum).map(
                  (type) => {
                    return {
                      title: usageMotivationTypeLabel(type),
                      value: type,
                    }
                  }
                )}
              />
              <Divider />
              <FormikRadioGroup
                name="acquireVehicles"
                formLabel={acquireVehicles}
                error={Boolean(
                  touched.acquireVehicles && errors.acquireVehicles
                )}
                options={booleanRadioType}
              />
            </CustomAccordion>
            {!isCommune && (
              <CustomAccordion
                id="commercialUse"
                title={commercialUse.accordionTitle}
              >
                <FormikRadioGroup
                  name="commercialUse"
                  error={Boolean(touched.commercialUse && errors.commercialUse)}
                  formLabel={commercialUse.hasCommercialUse}
                  tooltip={CustomTexts.tooltips.commercialUse}
                  options={Object.values(LocationUsageCommercialUseEnum).map(
                    (type) => {
                      return {
                        title: usageCommercialUseLabel(type),
                        value: type,
                      }
                    }
                  )}
                />
              </CustomAccordion>
            )}
          </Box>
          <NextButtonStepper
            disabled={isSubmitting}
            onClick={(): void => {
              validateForm().then((res) => {
                if (Object.keys(res).length > 0) {
                  openSnackbar(CustomTranslations.INVALID_FIELDS, 'error')
                }
                if (Object.keys(res).length === 0 && !dirty)
                  setActiveStep((prevActiveStep) => prevActiveStep + 1)
                else submitForm()
              })
            }}
          />
        </Form>
      )}
    </Formik>
  )
}

export default UsageForm
