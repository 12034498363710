import React, { useContext, useState } from 'react'
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import DeleteForever from '@material-ui/icons/DeleteForever'

import InfoCard from '../../../../../components/Card/InfoCard'
import {
  primaryPetrol50,
  primaryPetrolSoft,
  secondaryBlackBlue,
  secondaryLightBlue,
} from '../../../../../components/Theme/CustomColors'
import {
  ChargingStationGroup,
  ChargingStationGroupHardwareCostsStateEnum,
  ChargingStationGroupListReportTypeEnum,
  ChargingStationGroupLocationEnum,
} from '../../../../../services'
import CustomTexts from '../../../../../utils/lang/CustomTexts'
import CustomTranslations from '../../../../../utils/lang/CustomTranslations'
import ChargingStationsContext from '../../../../../context/ChargingStations'
import CustomButton from '../../../../../components/Button/CustomButton'
import { DefaultApi } from '../../../../../services/api/default-api'
import { SnackbarContext } from '../../../../../context/Snackbar'
import ChargingStationsDialog from './ChargingStationsDialog'
import {
  chargingStationGroupLocationLabel,
  hardwareCostStateLabel,
} from '../../../../../utils/labelHelper'

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      backgroundColor: primaryPetrol50,
      marginTop: '3.2rem',
    },
    button: {
      backgroundColor: primaryPetrolSoft,
      color: secondaryBlackBlue,
      display: 'block',
      margin: '2rem auto 1rem',
    },
    activeButton: {
      backgroundColor: secondaryLightBlue,
    },
    title: {
      wordBreak: 'break-all',
    },
    icon: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
)

const ChargingStationCards = (props: {
  reportType: ChargingStationGroupListReportTypeEnum | undefined
}): JSX.Element => {
  const classes = useStyles()
  const defaultApi = new DefaultApi()
  const { reportType } = props
  const { reportId, locationId } = useParams<{
    reportId: string
    locationId: string
  }>()
  const { openSnackbarError, openSnackbar } = useContext(SnackbarContext)
  const {
    setSelectedChargingStation,
    selectedChargingStation,
    setChargingStations,
    chargingStations,
  } = useContext(ChargingStationsContext)
  const isSingleStation = selectedChargingStation?.amount === 1
  const [deleteStation, setDeleteStation] = useState<{
    show: boolean
    groupId?: number
  }>({ show: false, groupId: undefined })
  let totalStations = 0
  const isPtJ = reportType === ChargingStationGroupListReportTypeEnum.Ptj
  const { groupCards, deleteStationDesc, deleteStationsDesc } =
    CustomTexts.chargingStations

  if (chargingStations.length > 0) {
    chargingStations.forEach((station: ChargingStationGroup): void => {
      if (station.amount) totalStations += station?.amount
    })
  }

  const closeDeleteModal = (): void =>
    setDeleteStation({ ...deleteStation, show: false })

  const deleteChargingStation = (): void => {
    if (deleteStation.groupId?.toString())
      if (locationId && reportId)
        defaultApi
          .reportReportIdLocationLocationIdChargingStationGroupGroupIdDelete(
            locationId,
            reportId,
            deleteStation.groupId.toString()
          )
          .then(() => {
            openSnackbar(CustomTranslations.DELETE_SUCCESS, 'success')
            closeDeleteModal()
            const filteredChargingStations = chargingStations.filter(
              (chargingStation) =>
                chargingStation.groupId !== deleteStation.groupId
            )
            setChargingStations(filteredChargingStations)
          })
          .catch((e: AxiosError) => {
            openSnackbarError(e)
            closeDeleteModal()
          })
  }

  const labelHelper = (key: string, value: string): string => {
    switch (key) {
      case 'location':
        return chargingStationGroupLocationLabel(
          value as ChargingStationGroupLocationEnum
        )
      case 'hardwareCostsState':
        return hardwareCostStateLabel(
          value as ChargingStationGroupHardwareCostsStateEnum
        )
      case 'hardwareCosts':
        return `${value},00`
      case 'launchDate':
        return new Date(value).toLocaleDateString('de-DE') ?? ''
      default:
        return value
    }
  }

  return (
    <Box>
      <Box className={classes.wrapper} p={2}>
        <Typography variant="h3">
          {groupCards.totalGroupAmount}
          <br />
          {totalStations}
        </Typography>
      </Box>
      {chargingStations.map(
        (chargingStation: ChargingStationGroup, index: number) => {
          const isActive =
            chargingStation.groupId === selectedChargingStation?.groupId
          return (
            <InfoCard
              key={chargingStation.groupId}
              title={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {chargingStation.amount === 1
                    ? groupCards.createdStation
                    : groupCards.createdStationGroup}
                  <DeleteForever
                    className={classes.icon}
                    onClick={(): void =>
                      setDeleteStation({
                        show: true,
                        groupId: chargingStation.groupId,
                      })
                    }
                  />
                </Box>
              }
              isActive={isActive}
              isLatest={index === 0}
            >
              <>
                {Object.entries({
                  amount: chargingStation.amount,
                  launchDate: chargingStation.launchDate,
                  location: chargingStation.location,
                  ...(!isPtJ && {
                    manufacturer: chargingStation.manufacturer,
                    model: chargingStation.model,
                    nLPkl11: chargingStation.nLPkl11,
                    nLPgrgl11klgl22: chargingStation.nLPgrgl11klgl22,
                    sLPgr22kl50: chargingStation.sLPgr22kl50,
                    sLPgrgl50kl100: chargingStation.sLPgrgl50kl100,
                    sLPgrgl100kl150: chargingStation.sLPgrgl100kl150,
                  }),
                  ...(isPtJ && {
                    sLPgrgl50kl100: chargingStation.sLPgrgl50kl100,
                    sLPgrgl100kl150: chargingStation.sLPgrgl100kl150,
                    sLPgrgl150kl300: chargingStation.sLPgrgl150kl300,
                    sLPgrgl300: chargingStation.sLPgrgl300,
                  }),
                  electricityType: chargingStation.electricityType,
                  hardwareCosts: chargingStation.hardwareCosts,
                  hardwareCostsState: chargingStation.hardwareCostsState,
                }).map((entry) => {
                  const key = entry[0] as string
                  if (key !== 'groupId')
                    return (
                      <Grid container spacing={2} key={key}>
                        <Grid item xs={12} md={7}>
                          <Typography variant="body1">
                            {
                              CustomTexts.summary.chargingStations[
                                key as keyof typeof CustomTexts.summary.chargingStations
                              ]
                            }
                            {key !== 'hardwareCostsState' ? ':' : ''}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <Typography className={classes.title} variant="body1">
                            {labelHelper(key, entry[1] as string)}
                          </Typography>
                        </Grid>
                      </Grid>
                    )
                  return <div key={key} />
                })}
              </>
              <CustomButton
                className={
                  isActive
                    ? `${classes.button} ${classes.activeButton}`
                    : classes.button
                }
                onClick={(): void =>
                  setSelectedChargingStation(chargingStation)
                }
              >
                {CustomTranslations.EDIT}
              </CustomButton>
            </InfoCard>
          )
        }
      )}
      <ChargingStationsDialog
        title={isSingleStation ? deleteStationDesc : deleteStationsDesc}
        btnTextSubmit={CustomTexts.dialog.deleteTextSubmit}
        btnTextCancel={CustomTexts.dialog.cancel}
        handleClose={closeDeleteModal}
        handleCancel={closeDeleteModal}
        handleSubmit={deleteChargingStation}
        open={deleteStation.show}
      />
    </Box>
  )
}

export default ChargingStationCards
