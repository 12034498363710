import { Dispatch, SetStateAction, useContext } from 'react'
import { Form, Formik, FormikValues } from 'formik'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import deLocale from 'date-fns/locale/de'
import { Box, Divider } from '@material-ui/core'
import Yup from '../../../utils/lang/ValidationTranslation'
import {
  ChargingStationGroup,
  ChargingStationGroupElectricityTypeEnum,
  ChargingStationGroupHardwareCostsStateEnum,
  ChargingStationGroupListReportTypeEnum,
  ChargingStationGroupLocationEnum,
  ChargingStationModelListItem,
  DefaultApi,
} from '../../../services'
import { SnackbarContext } from '../../../context/Snackbar'
import CustomAccordion, {
  AccordionDetailsTitle,
} from '../../../components/Accordion/CustomAccordion'
import CustomTexts from '../../../utils/lang/CustomTexts'
import CustomTranslations from '../../../utils/lang/CustomTranslations'
import FormikDatepicker from '../../../components/Input/FormikDatepicker'
import FormikAutocomplete from '../../../components/Input/FormikAutcomplete'
import FormikSelect from '../../../components/Input/FormikSelect'
import {
  chargingStationGroupLocationLabel,
  electricityTypeLabel,
  hardwareCostStateLabel,
} from '../../../utils/labelHelper'
import FormikRadioGroup from '../../../components/Input/FormikRadioGroup'
import {
  primaryPetrolSoft,
  secondaryBlackBlue,
} from '../../../components/Theme/CustomColors'
import CustomButton from '../../../components/Button/CustomButton'
import ChargingStationsContext from '../../../context/ChargingStations'
import FormikNumberField from '../../../components/Input/FormikNumberField'

interface ChargingStationProps {
  reportType: ChargingStationGroupListReportTypeEnum | undefined
  chargingStation: ChargingStationGroup
  manufacturerList: { [index: string]: string }[]
  manufacturerModelList: ChargingStationModelListItem[]
  setIsSingleStation: Dispatch<SetStateAction<boolean | undefined>>
}

const { SAVE, SAVE_CREATE, SAVE_SUCCESS, AMOUNT_LABEL, COSTS_LABEL } =
  CustomTranslations
const { maxChargingStations, minChargingStations } = CustomTexts.validation
const {
  amount,
  amountTitle,
  generalInformationTitle,
  launchDate,
  manufacturer,
  model,
  chargingPointsTitle,
  chargingPoints,
  hardwareCosts,
  hardwareTitle,
  location,
  locationLabel,
  electricityType,
  nLPgrgl11klgl22,
  nLPkl11,
  sLPgrgl50kl100,
  sLPgr22kl50,
  sLPgrgl100kl150,
  sLPgrgl150kl300,
  sLPgrgl300,
} = CustomTexts.form.chargingStationGroup

const START_DATE = new Date('2021-11-23')

const ChargingStationForm = (props: ChargingStationProps): JSX.Element => {
  const defaultApi = new DefaultApi()
  const {
    chargingStation,
    manufacturerList,
    manufacturerModelList,
    setIsSingleStation,
    reportType,
  } = props

  const isPtj = reportType === ChargingStationGroupListReportTypeEnum.Ptj

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .min(1, minChargingStations)
      .max(100, maxChargingStations)
      .required(),
    electricityType: Yup.string()
      .oneOf(Object.values(ChargingStationGroupElectricityTypeEnum))
      .required(),
    hardwareCosts: Yup.number().min(0).required(),
    hardwareCostsState: Yup.string()
      .oneOf(Object.values(ChargingStationGroupHardwareCostsStateEnum))
      .required(),
    launchDate: Yup.date()
      .required()
      .nullable()
      .transform((v) => (v instanceof Date ? v : null))
      .min(START_DATE, CustomTranslations.INVALID_MIN_DATE)
      .max(
        new Date(Date.now() + 3600 * 1000 * 24),
        CustomTranslations.INVALID_DATE_FUTURE
      ),
    location: Yup.string()
      .oneOf(Object.values(ChargingStationGroupLocationEnum))
      .required(),
    ...(!isPtj && {
      model: Yup.string().required(),
      manufacturer: Yup.string().required(),
      nLPkl11: Yup.number().min(0).required(),
      nLPgrgl11klgl22: Yup.number().min(0).required(),
      sLPgr22kl50: Yup.number().min(0).required(),
    }),
    ...(isPtj && {
      sLPgrgl150kl300: Yup.number().min(0).required(),
      sLPgrgl300: Yup.number().min(0).required(),
    }),
    sLPgrgl50kl100: Yup.number().min(0).required(),
    sLPgrgl100kl150: Yup.number().min(0).required(),
  })

  const { reportId, locationId } = useParams<{
    reportId: string
    locationId: string
  }>()
  const { openSnackbar, openSnackbarError } = useContext(SnackbarContext)
  const { chargingStations, setChargingStations, setSelectedChargingStation } =
    useContext(ChargingStationsContext)
  const isChargingStation = chargingStation.amount === 1

  const handleSubmit = (values: FormikValues): void => {
    setIsSingleStation(undefined)

    const ptjValues = {
      model: undefined,
      manufacturer: undefined,
      nLPkl11: undefined,
      nLPgrgl11klgl22: undefined,
      sLPgr22kl50: undefined,
      sLPgrgl100kl150: values.sLPgrgl100kl150 ?? undefined,
      sLPgrgl150kl300: values.sLPgrgl150kl300 ?? undefined,
      sLPgrgl300: values.sLPgrgl300 ?? undefined,
    }

    const defaultValues = {
      model: values.model ?? undefined,
      manufacturer: values.manufacturer ?? undefined,
      nLPkl11: values.nLPkl11 ?? undefined,
      nLPgrgl11klgl22: values.nLPgrgl11klgl22 ?? undefined,
      sLPgr22kl50: values.sLPgr22kl50 ?? undefined,
      sLPgrgl100kl150: values.sLPgrgl100kl150 ?? undefined,
      sLPgrgl150kl300: undefined,
      sLPgrgl300: undefined,
    }

    const payload = {
      ...values,
      ...(!isPtj ? defaultValues : ptjValues),
      sLPgrgl50kl100: values.sLPgrgl50kl100 ?? undefined,
    }

    if (locationId && reportId)
      if (chargingStation.groupId?.toString())
        defaultApi
          .reportReportIdLocationLocationIdChargingStationGroupGroupIdPut(
            locationId,
            reportId,
            chargingStation.groupId.toString(),
            payload
          )
          .then((res) => {
            openSnackbar(SAVE_SUCCESS, 'success')
            const mappedChargingStation = chargingStations.map((item) => {
              if (item.groupId === chargingStation.groupId) return res.data
              return item
            })
            setChargingStations(mappedChargingStation)
            setSelectedChargingStation(undefined)
          })
          .catch((e: AxiosError) => openSnackbarError(e))
      else
        defaultApi
          .reportReportIdLocationLocationIdChargingStationGroupPost(
            locationId,
            reportId,
            payload
          )
          .then((res) => {
            openSnackbar(SAVE_SUCCESS, 'success')
            setChargingStations([res.data].concat(chargingStations))
            setSelectedChargingStation(undefined)
          })
          .catch((e: AxiosError) => openSnackbarError(e))
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
      <Formik
        enableReinitialize
        initialValues={{
          ...chargingStation,
          location: chargingStation.location ?? '',
          electricityType: chargingStation.electricityType ?? '',
          hardwareCostsState: chargingStation.hardwareCostsState ?? '',
          amount: chargingStation.amount ?? '',
          nLPkl11: chargingStation.nLPkl11 ?? '',
          nLPgrgl11klgl22: chargingStation.nLPgrgl11klgl22 ?? '',
          sLPgr22kl50: chargingStation.sLPgr22kl50 ?? '',
          sLPgrgl50kl100: chargingStation.sLPgrgl50kl100 ?? '',
          sLPgrgl100kl150: chargingStation.sLPgrgl100kl150 ?? '',
          sLPgrgl150kl300: chargingStation.sLPgrgl150kl300 ?? '',
          sLPgrgl300: chargingStation.sLPgrgl300 ?? '',
          hardwareCosts: chargingStation.hardwareCosts ?? '',
          launchDate: chargingStation.launchDate ?? null,
        }}
        validationSchema={validationSchema}
        onSubmit={(values): void => handleSubmit(values)}
      >
        {({
          submitForm,
          values,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
          isSubmitting,
          setSubmitting,
          validateForm,
        }): JSX.Element => (
          <Form autoComplete="off">
            {isChargingStation ? (
              <></>
            ) : (
              <CustomAccordion
                id="amount"
                title={amountTitle}
                tooltipTitle={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: CustomTexts.tooltips.amount,
                    }}
                  />
                }
                defaultExpanded
                isExpanded
              >
                <FormikNumberField
                  name="amount"
                  label={AMOUNT_LABEL}
                  placeholder={AMOUNT_LABEL}
                  title={amount}
                  error={Boolean(touched.amount && errors.amount)}
                />
              </CustomAccordion>
            )}
            <CustomAccordion
              id="generalInformation"
              title={generalInformationTitle}
              defaultExpanded={isChargingStation}
              isExpanded={isChargingStation}
            >
              <AccordionDetailsTitle title={launchDate} margin="0 0 0.8rem" />
              <FormikDatepicker
                name="launchDate"
                error={Boolean(touched.launchDate && errors.launchDate)}
                label={launchDate}
                minDate={START_DATE}
                disableFuture
                lightTheme
              />
              <Divider style={{ marginBottom: '1rem' }} />
              {reportType === ChargingStationGroupListReportTypeEnum.Ptj ? (
                <></>
              ) : (
                <>
                  <AccordionDetailsTitle
                    title={manufacturer}
                    tooltip={CustomTexts.tooltips.manufacturer}
                    margin="0 0 0.8rem"
                  />
                  <FormikAutocomplete
                    name="manufacturer"
                    error={Boolean(touched.manufacturer && errors.manufacturer)}
                    label={manufacturer}
                    options={manufacturerList}
                    onChange={(e, value) => {
                      setFieldValue('manufacturer', value?.manufacturer)
                      setFieldValue('model', '')
                    }}
                    onOpen={() => setFieldTouched('manufacturer', true)}
                    value={{ manufacturer: values.manufacturer ?? '' }}
                    getOptionLabel={(option) => option.manufacturer as string}
                    renderOption={(option) => option.manufacturer}
                    getOptionSelected={(option, value) =>
                      !!(
                        value.manufacturer === '' ||
                        option.manufacturer === value.manufacturer
                      )
                    }
                  />
                  <Divider style={{ marginBottom: '1rem' }} />
                  <AccordionDetailsTitle
                    title={model}
                    tooltip={CustomTexts.tooltips.model}
                    margin="0 0 0.8rem"
                  />
                  <FormikAutocomplete
                    name="model"
                    error={Boolean(touched.model && errors.model)}
                    label={model}
                    options={
                      values.manufacturer
                        ? (manufacturerModelList.filter(
                            (item) => item.manufacturer === values.manufacturer
                          ) as Record<string, unknown>[])
                        : (manufacturerModelList as Record<string, unknown>[])
                    }
                    onOpen={() => setFieldTouched('model', true)}
                    onChange={(e, value) =>
                      setFieldValue('model', value?.model)
                    }
                    getOptionLabel={(option) => option.model as string}
                    renderOption={(option) => option.model}
                    value={{ model: values.model ?? '' }}
                    getOptionSelected={(option, value) =>
                      !!(value.model === '' || option.model === value.model)
                    }
                  />
                  <Divider />
                </>
              )}
              <AccordionDetailsTitle title={location} margin="0 0 0.8rem" />
              <FormikSelect
                name="location"
                label={locationLabel}
                error={Boolean(touched.location && errors.location)}
                options={Object.values(ChargingStationGroupLocationEnum).map(
                  (type) => {
                    return {
                      title: chargingStationGroupLocationLabel(type),
                      value: type,
                    }
                  }
                )}
              />
            </CustomAccordion>
            <CustomAccordion id="chargingPoints" title={chargingPointsTitle}>
              <AccordionDetailsTitle title={chargingPoints} />
              {isPtj ? (
                <>
                  <FormikNumberField
                    name="sLPgrgl50kl100"
                    label={AMOUNT_LABEL}
                    placeholder={AMOUNT_LABEL}
                    title={sLPgrgl50kl100}
                    error={Boolean(
                      touched.sLPgrgl50kl100 && errors.sLPgrgl50kl100
                    )}
                  />
                  <FormikNumberField
                    name="sLPgrgl100kl150"
                    label={AMOUNT_LABEL}
                    placeholder={AMOUNT_LABEL}
                    title={sLPgrgl100kl150}
                    error={Boolean(
                      touched.sLPgrgl100kl150 && errors.sLPgrgl100kl150
                    )}
                  />
                  <FormikNumberField
                    name="sLPgrgl150kl300"
                    label={AMOUNT_LABEL}
                    placeholder={AMOUNT_LABEL}
                    title={sLPgrgl150kl300}
                    error={Boolean(
                      touched.sLPgrgl150kl300 && errors.sLPgrgl150kl300
                    )}
                  />
                  <FormikNumberField
                    name="sLPgrgl300"
                    label={AMOUNT_LABEL}
                    placeholder={AMOUNT_LABEL}
                    title={sLPgrgl300}
                    error={Boolean(touched.sLPgrgl300 && errors.sLPgrgl300)}
                  />
                </>
              ) : (
                <>
                  <FormikNumberField
                    name="nLPkl11"
                    label={AMOUNT_LABEL}
                    placeholder={AMOUNT_LABEL}
                    title={nLPkl11}
                    error={Boolean(touched.nLPkl11 && errors.nLPkl11)}
                  />
                  <FormikNumberField
                    name="nLPgrgl11klgl22"
                    label={AMOUNT_LABEL}
                    placeholder={AMOUNT_LABEL}
                    title={nLPgrgl11klgl22}
                    error={Boolean(
                      touched.nLPgrgl11klgl22 && errors.nLPgrgl11klgl22
                    )}
                  />
                  <FormikNumberField
                    name="sLPgr22kl50"
                    label={AMOUNT_LABEL}
                    placeholder={AMOUNT_LABEL}
                    title={sLPgr22kl50}
                    error={Boolean(touched.sLPgr22kl50 && errors.sLPgr22kl50)}
                  />
                  <FormikNumberField
                    name="sLPgrgl50kl100"
                    label={AMOUNT_LABEL}
                    placeholder={AMOUNT_LABEL}
                    title={sLPgrgl50kl100}
                    error={Boolean(
                      touched.sLPgrgl50kl100 && errors.sLPgrgl50kl100
                    )}
                  />
                  <FormikNumberField
                    name="sLPgrgl100kl150"
                    label={AMOUNT_LABEL}
                    placeholder={AMOUNT_LABEL}
                    title={sLPgrgl100kl150}
                    error={Boolean(
                      touched.sLPgrgl100kl150 && errors.sLPgrgl100kl150
                    )}
                  />
                </>
              )}
              <Box mt={4}>
                <FormikRadioGroup
                  name="electricityType"
                  error={Boolean(
                    touched.electricityType && errors.electricityType
                  )}
                  formLabel={electricityType}
                  options={Object.values(
                    ChargingStationGroupElectricityTypeEnum
                  ).map((type) => {
                    return {
                      title: electricityTypeLabel(type),
                      value: type,
                    }
                  })}
                />
              </Box>
            </CustomAccordion>
            <CustomAccordion id="hardwareCosts" title={hardwareTitle}>
              <FormikNumberField
                name="hardwareCosts"
                label={COSTS_LABEL}
                placeholder="1000"
                title={hardwareCosts}
                error={Boolean(touched.hardwareCosts && errors.hardwareCosts)}
                tooltip={CustomTexts.tooltips.hardwareCosts}
              />
              <br />
              <FormikRadioGroup
                name="hardwareCostsState"
                error={Boolean(
                  touched.hardwareCostsState && errors.hardwareCostsState
                )}
                formLabel=""
                options={[
                  ChargingStationGroupHardwareCostsStateEnum.Preliminary,
                  ChargingStationGroupHardwareCostsStateEnum.Invoice,
                ].map((type) => {
                  return {
                    title: hardwareCostStateLabel(type),
                    value: type,
                  }
                })}
              />
            </CustomAccordion>
            <Box textAlign="center" mt={6}>
              <CustomButton
                disabled={isSubmitting}
                fontcolor={secondaryBlackBlue}
                backgroundcolor={primaryPetrolSoft}
                onClick={(): void => {
                  validateForm().then((res) => {
                    if (Object.keys(res).length > 0) {
                      openSnackbar(CustomTranslations.INVALID_FIELDS, 'error')
                      submitForm()
                      setSubmitting(false)
                    } else submitForm()
                  })
                }}
              >
                {chargingStation.groupId ? SAVE : SAVE_CREATE}
              </CustomButton>
            </Box>
          </Form>
        )}
      </Formik>
    </MuiPickersUtilsProvider>
  )
}

export default ChargingStationForm
