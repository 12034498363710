import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import CustomTitle from '../../../../components/Title/CustomTitle'
import CustomTexts from '../../../../utils/lang/CustomTexts'
import LoadingAnimation from '../../../../components/Loader/LoadingAnimation'
import ChargingStationForm from '../../Form/ChargingStationsForm'
import { SnackbarContext } from '../../../../context/Snackbar'
import {
  ChargingStationGroup,
  ChargingStationGroupListReportTypeEnum,
  DefaultApi,
} from '../../../../services'
import ChargingStationsIcon from '../../../../assets/images/icons/ChargingStationsIcon.svg'
import ChargingStationIcon from '../../../../assets/images/icons/ChargingStationIcon.svg'
import ChargingStationsContext from '../../../../context/ChargingStations'
import ChargingStationCards from './Components/ChargingStationCards'
import BackButtonStepper from '../../../../components/Button/BackButtonStepper'
import StepperContext from '../../../../context/Stepper'
import NextButtonStepper from '../../../../components/Button/NextButtonStepper'
import InfoCollapseButton from '../../../../components/Button/InfoCollapseButton'
import {
  useManufacturerList,
  useManufacturerModelList,
} from '../../../../hooks/Autocomplete'
import ChargingStationsDialog from './Components/ChargingStationsDialog'
import CustomButton from '../../../../components/Button/CustomButton'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      marginBottom: '2rem',
    },
    responsive: {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        '& > .MuiBox-root': {
          marginBottom: '2rem',
        },
      },
    },
    hideXs: {
      display: 'none',
    },
  })
)

const LocationChargingStations = (): JSX.Element => {
  const defaultApi = new DefaultApi()
  const { openSnackbarError } = useContext(SnackbarContext)
  const { setActiveStep } = useContext(StepperContext)
  const { reportId, locationId } = useParams<{
    reportId: string
    locationId: string
  }>()
  const manufacturerList = useManufacturerList()
  const manufacturerModelList = useManufacturerModelList()
  const [loading, setLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isSingleStation, setIsSingleStation] = useState<boolean | undefined>(
    undefined
  )
  const [chargingStations, setChargingStations] = useState<
    ChargingStationGroup[]
  >([])
  const [reportType, setReportType] = useState<
    ChargingStationGroupListReportTypeEnum | undefined
  >(undefined)
  const [selectedChargingStation, setSelectedChargingStation] = useState<
    ChargingStationGroup | undefined
  >(undefined)

  const classes = useStyles()
  const {
    title,
    subtitle,
    howToCard,
    singleStationBtnText,
    groupStationBtnText,
    copySingleStationDesc,
    copyStationsDesc,
  } = CustomTexts.chargingStations

  useEffect(() => {
    setLoading(true)
    if (locationId && reportId)
      defaultApi
        .reportReportIdLocationLocationIdChargingStationGroupsGet(
          locationId,
          reportId
        )
        .then((res) => {
          setReportType(res.data.reportType)
          setChargingStations(
            (res.data.entities ?? []).reverse() as ChargingStationGroup[]
          )
          setLoading(false)
        })
        .catch((e: AxiosError) => {
          openSnackbarError(e)
          setLoading(false)
        })
  }, [])

  const closeModal = (): void => setShowModal(false)
  const openModal = (): void => {
    if (chargingStations.length > 0) setShowModal(true)
  }

  const addNewChargingStationGroup = (): void => {
    const initialChargingStation = {
      amount: isSingleStation === true ? 1 : undefined,
      electricityType: undefined,
      groupId: undefined,
      hardwareCosts: undefined,
      hardwareCostsState: undefined,
      launchDate: undefined,
      location: undefined,
      model: undefined,
      manufacturer: undefined,
      nLPgrgl11klgl22: undefined,
      nLPkl11: undefined,
      sLPgrgl50kl100: undefined,
      sLPgr22kl50: undefined,
      sLPgrgl100kl150: undefined,
      sLPgrgl150kl300: undefined,
      sLPgrgl300: undefined,
    }
    setSelectedChargingStation(initialChargingStation)
    closeModal()
  }

  const copyChargingStation = (): void => {
    setSelectedChargingStation({
      ...chargingStations[0],
      amount: isSingleStation === true ? 1 : undefined,
      groupId: undefined,
    })
    closeModal()
  }

  useEffect(() => {
    if (chargingStations.length === 0 && isSingleStation !== undefined)
      addNewChargingStationGroup()
  }, [isSingleStation])

  return (
    <>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <ChargingStationsContext.Provider
          value={{
            chargingStations,
            setChargingStations,
            selectedChargingStation,
            setSelectedChargingStation,
          }}
        >
          <Grid container spacing={3}>
            <Grid item md={1} className={classes.hideXs} />
            <Grid item xs={12} md={7}>
              <CustomTitle title={title} subtitle={subtitle} />
              <Box mb={7}>
                <InfoCollapseButton nextToIcon belowIcon={false}>
                  <Box pl={1} pr={4} pb={4} pt={4}>
                    <Typography variant="h4">{howToCard.titleOne}</Typography>
                    <Typography variant="body2" className={classes.body}>
                      {howToCard.contentOne}
                    </Typography>
                    <Typography variant="h4">{howToCard.titleTwo}</Typography>
                    <Typography variant="body2">
                      {howToCard.contentTwo}
                    </Typography>
                  </Box>
                </InfoCollapseButton>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                mt={4}
                mb={5}
                px={3}
                className={classes.responsive}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  style={{
                    opacity: isSingleStation === false ? 1 : 0.5,
                  }}
                >
                  <img
                    src={ChargingStationsIcon}
                    alt="charging stations icon"
                  />
                  <CustomButton
                    onClick={(): void => {
                      setIsSingleStation(false)
                      openModal()
                    }}
                  >
                    {groupStationBtnText}
                  </CustomButton>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  style={{
                    opacity: isSingleStation ?? 1 ? 1 : 0.5,
                  }}
                >
                  <img src={ChargingStationIcon} alt="charging station icon" />
                  <CustomButton
                    onClick={(): void => {
                      setIsSingleStation(true)
                      openModal()
                    }}
                  >
                    {singleStationBtnText}
                  </CustomButton>
                </Box>
              </Box>
              <Box minHeight={chargingStations.length > 0 ? 105 : 'unset'}>
                {selectedChargingStation && (
                  <>
                    <Box width="100%" textAlign="right" mb={1}>
                      <Typography variant="body2">
                        {CustomTexts.form.allFieldsRequired}
                      </Typography>
                    </Box>
                    <ChargingStationForm
                      chargingStation={selectedChargingStation}
                      manufacturerList={manufacturerList}
                      setIsSingleStation={setIsSingleStation}
                      manufacturerModelList={manufacturerModelList}
                      reportType={reportType}
                    />
                  </>
                )}
              </Box>
              <Divider />
              <Box mx={3}>
                <BackButtonStepper />
                <NextButtonStepper
                  disabled={chargingStations.length === 0}
                  onClick={(): void =>
                    setActiveStep((prevActiveStep) => prevActiveStep + 1)
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <ChargingStationCards reportType={reportType} />
            </Grid>
          </Grid>
        </ChargingStationsContext.Provider>
      )}
      <ChargingStationsDialog
        title={isSingleStation ? copySingleStationDesc : copyStationsDesc}
        btnTextSubmit={CustomTexts.dialog.copyTextSubmit}
        btnTextCancel={CustomTexts.dialog.copyTextCancel}
        handleClose={(): void => closeModal()}
        handleSubmit={(): void => copyChargingStation()}
        handleCancel={(): void => addNewChargingStationGroup()}
        open={showModal}
      />
    </>
  )
}

export default LocationChargingStations
