import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, { useContext, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import CustomButton from '../components/Button/CustomButton'
import ColoredCard from '../components/Card/ColoredCard'
import { primaryPetrol50 } from '../components/Theme/CustomColors'
import CustomTitle from '../components/Title/CustomTitle'
import { DefaultApi } from '../services'
import CustomTexts from '../utils/lang/CustomTexts'
import { SnackbarContext } from '../context/Snackbar'
import CustomTooltip from '../components/Tooltip/CustomTooltip'

const useStyles = makeStyles(() =>
  createStyles({
    cardTitle: {
      marginBottom: '3rem',
    },

    collapseButton: {
      padding: '0 4.2rem 2.5rem',
    },

    checkBoxWithLabel: {
      paddingBottom: '3.5rem',
      whiteSpace: 'pre-wrap',
    },
  })
)

export const ChargingUpload = (): JSX.Element => {
  const classes = useStyles()
  const { warning, success, title, card: cardTrans } = CustomTexts.csvUpload

  const inputRef = useRef<HTMLInputElement>(null)

  const { reportId, locationId } = useParams<{
    reportId: string
    locationId: string
  }>()

  const { openSnackbar, openSnackbarError } = useContext(SnackbarContext)

  const defaultApi = new DefaultApi()

  const getBase64 = (file: File): Promise<unknown> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
      reader.readAsDataURL(file)
    })
  }

  const handleUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const file = event.target.files?.[0]

    if (!file) return

    if (file.size > 1_000_000) {
      openSnackbar(warning, 'error')
      return
    }

    const base64data = await getBase64(file)
    const fileName = file.name

    if (locationId && reportId)
      defaultApi
        .reportReportIdLocationLocationIdChargingDataPost(
          locationId,
          reportId,
          {
            fileName,
            base64File: base64data as string,
          },
          {
            headers: {
              'content-type': `multipart/form-data; boundary=--${Date.now()}`,
              accept: '*/*',
            },
          }
        )
        .then(() => {
          openSnackbar(success, 'success')
        })
        .catch((e: AxiosError) => {
          openSnackbarError(e)
        })
        .finally(() => {
          if (inputRef.current) {
            inputRef.current.value = ''
          }
        })
  }

  return (
    <>
      <Grid container>
        <Grid item md={1} />
        <Grid item md={7} xs={12}>
          <CustomTitle title={title} />
          <Box>
            <ColoredCard
              backgroundcolor={primaryPetrol50}
              padding="1.8rem 2.4rem 3.5rem"
            >
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h4" className={classes.cardTitle}>
                  {cardTrans.title}
                </Typography>
                <CustomTooltip
                  title={CustomTexts.tooltips.upload}
                  margin="0 0 1rem"
                />
              </Box>

              <label htmlFor="contained-button-file">
                <input
                  ref={inputRef}
                  accept=".csv, .xls, .xlsx"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={handleUpload}
                />

                <CustomButton component="span">{cardTrans.button}</CustomButton>
              </label>
            </ColoredCard>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
